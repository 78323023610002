
export default {
  data: () => ({
    text: ''
  }),
  methods: {
    hideModal () {
      this.text = ''
    },
    searchCatalog () {
      window.location.assign(`/catalog/index/term/?q=${this.text}`)
    }
  }
}
