import { render, staticRenderFns } from "@/components/templates/default/PageHeaderNuxt/menu/men/men.html?vue&type=template&id=0906b8c0&scoped=true&lang=html&"
import script from "./men.js?vue&type=script&lang=js&"
export * from "./men.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderNuxt/menu/men/men.scss?vue&type=style&index=0&id=0906b8c0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0906b8c0",
  null
  
)

component.options.__file = "men.vue"
export default component.exports