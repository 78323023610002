export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        id: 0,
        text: 'buying_guides_advice',
        link: '/guides/'
      },
      {
        id: 1,
        text: 'eye_health_advice',
        link: '/eye-health/'
      },
      {
        id: 2,
        text: 'how_guides',
        link: '/guides/how-to/'
      },
      {
        id: 3,
        text: 'lens_guidance',
        link: '/guides/lenses/'
      },
      {
        id: 4,
        text: 'LensPriceList_lc',
        link: '/price-list/'
      },
      {
        id: 5,
        text: 'ShopInStore',
        link: '/store/'
      }
    ]
  })
}
