export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        id: 0,
        text: 'Accessories',
        link: '/accessories/'
      },
      {
        id: 1,
        text: 'ReadyReaders',
        link: '/accessories/reading-glasses/'
      },
      {
        id: 2,
        text: 'ski_goggles',
        link: '/goggles/'
      },
      {
        id: 3,
        text: 'lens_replacement_text',
        link: '/lens-replacement/'
      },
      {
        id: 4,
        text: 'KidsSunglasses',
        link: '/guides/kids-sunglasses/'
      }
    ]
  })
}
