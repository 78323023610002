import Contacts from '@/components/scripts/PageHeaderNuxt/contacts/contacts.vue'
import Logo from '@/components/scripts/PageHeaderNuxt/logo/logo.vue'
import Navigation from '@/components/scripts/PageHeaderNuxt/menu/navigation/navigation.vue'
import NavigationMobile from '@/components/scripts/PageHeaderNuxt/menu/navigation-mobile/navigation-mobile.vue'

export default {
  name: 'PageHeaderNuxt',
  components: {
    Contacts,
    Logo,
    Navigation,
    NavigationMobile
  },
  data: () => ({
    isOpen: false
  }),
  computed: {
    showMenu: function () {
      const path = this.$route.path
      const routesForNuxtHeader = new RegExp('checkout|auth')
      return routesForNuxtHeader.test(path)
    }
  },
  created () {
    this.$i18n.locale = this.$cookies.get('lang') ? this.$cookies.get('lang') : 'en'
  },
  methods: {
    showSliderMenu () {
      this.isOpen = !this.isOpen
    },
    hideSliderMenu () {
      this.isOpen = !this.isOpen
    }
  }
}

