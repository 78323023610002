import dropdown from '../men-women-dropdown/men-women-dropdown.vue'

export default {
  components: {
    dropdown
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        id: 0,
        name: 'value-rate',
        text: 'global.great_value_as_seen_on_tv',
        image: 'https://d9qzjwuieyamt.cloudfront.net/public/en/res/images/items/fullsized/1602_01_1.jpg?ih20190903',
        link: '/cheap-glasses/'
      },
      {
        id: 1,
        name: 'essential_caps',
        text: 'global.all_favourite_frames_lenses_coatings',
        image: 'https://d9qzjwuieyamt.cloudfront.net/public/en/res/images/items/fullsized/1602_01_1.jpg?ih20190903',
        link: '/glasses/prescription/essential/'
      },
      {
        id: 2,
        name: 'designer_caps',
        text: 'global.great_brands_prices_including_more',
        image: 'https://d9qzjwuieyamt.cloudfront.net/public/en/res/images/items/fullsized/1602_01_1.jpg?ih20190903',
        link: '/glasses/prescription/designer/'
      },
      {
        id: 3,
        name: 'boutique_caps',
        text: 'global.collection_iconic_sophisticated_brands',
        image: 'https://d9qzjwuieyamt.cloudfront.net/public/en/res/images/items/fullsized/1602_01_1.jpg?ih20190903',
        link: '/glasses/prescription/boutique/'
      }
    ],
    categories: [
      [
        {
          id: 0,
          name: 'WhatsNew',
          link: '/glasses/prescription/new-items/'
        },
        {
          id: 1,
          name: '2for1Glasses',
          link: '/2for1-glasses/'
        },
        {
          id: 2,
          name: 'express_service',
          link: '/glasses/prescription/express/'
        },
        {
          id: 3,
          name: 'men_sunglasses',
          link: '/sunglasses/designer/mens/'
        },
        {
          id: 4,
          name: 'PrescriptionGlasses',
          link: '/glasses/prescription/mens/'
        },
        {
          id: 5,
          name: 'reglaze_your_sunglasses',
          link: '/lens-replacement/'
        }
      ],
      [
        {
          id: 0,
          name: 'ShopByStyle',
          link: '/',
          arrow: '&raquo;',
          subparams: [
            {
              id: 0,
              name: 'FrameTypeRimless',
              link: '/glasses/prescription/rimless/'
            },
            {
              id: 1,
              name: 'FrameTypeSRim',
              link: '/glasses/prescription/semi-rimless/'
            },
            {
              id: 2,
              name: 'Aviator',
              link: '/glasses/prescription/aviator/'
            },
            {
              id: 3,
              name: 'retro_wayfarrer',
              link: '/glasses/prescription/wayfarer/'
            },
            {
              id: 4,
              name: 'ShopRound',
              link: '/glasses/prescription/round-or-oval/'
            },
            {
              id: 5,
              name: 'Square',
              link: '/glasses/prescription/square-or-rectangular/'
            },
            {
              id: 6,
              name: 'CatEye',
              link: '/glasses/prescription/cat-eye/'
            },
            {
              id: 7,
              name: 'HornRimmed',
              link: '/glasses/prescription/horn-rimmed/'
            }
          ]
        },
        {
          id: 1,
          name: 'ShopByBrand',
          link: '/brands/',
          arrow: '&raquo;',
          subparams: [
            {
              id: 0,
              name: 'Oakley',
              link: '/glasses/prescription/oakley/'
            },
            {
              id: 1,
              name: 'Ray-Ban',
              link: '/glasses/prescription/ray-ban/'
            },
            {
              id: 2,
              name: 'Bolle',
              link: '/glasses/prescription/bolle/'
            },
            {
              id: 3,
              name: 'Tom Ford',
              link: '/glasses/prescription/tom-ford/'
            },
            {
              id: 4,
              name: 'Persol',
              link: '/glasses/prescription/persol/'
            },
            {
              id: 5,
              name: 'Tommy Hilfiger',
              link: '/glasses/prescription/tommy-hilfiger/'
            },
            {
              id: 6,
              name: 'William Morris',
              link: '/glasses/prescription/william-morris/'
            },
            {
              id: 7,
              name: 'More',
              link: '/brands/'
            }
          ]
        },
        {
          id: 2,
          name: 'ShopFrameColour',
          link: '/',
          arrow: '&raquo;',
          subparams: [
            {
              id: 0,
              name: 'ColorBlack',
              link: '/'
            },
            {
              id: 1,
              name: 'color_grey_dark',
              link: '/'
            },
            {
              id: 2,
              name: 'ColorBrown',
              link: '/'
            },
            {
              id: 3,
              name: 'ColorBlue',
              link: '/'
            },
            {
              id: 4,
              name: 'ColorRed',
              link: '/'
            },
            {
              id: 5,
              name: 'ColorPink',
              link: '/'
            },
            {
              id: 6,
              name: 'ColorOrange',
              link: '/'
            },
            {
              id: 7,
              name: 'ColorGreen',
              link: '/'
            }
          ]
        },
        {
          id: 3,
          name: 'shop_sport',
          link: '/',
          arrow: '&raquo;',
          subparams: [
            {
              id: 0,
              name: 'ActiveLifestyle',
              link: '/sunglasses/designer/active-lifestyle/'
            },
            {
              id: 1,
              name: 'RxInserts',
              link: '/'
            },
            {
              id: 2,
              name: 'Golf',
              link: '/sunglasses/designer/golf/'
            },
            {
              id: 3,
              name: 'Cycling',
              link: '/sunglasses/designer/cycling/'
            },
            {
              id: 4,
              name: 'Fishing',
              link: '/sunglasses/designer/fishing/'
            },
            {
              id: 5,
              name: 'Driving',
              link: '/sunglasses/designer/driving/'
            }
          ]
        },
        {
          id: 4,
          name: 'ski_goggles',
          link: '/goggles/'
        },
        {
          id: 5,
          name: 'our_lenses_guides',
          link: '/guides/lenses/'
        }
      ]
    ]
  }),
  methods: {
    nameSubmenu (value) {
      this.$emit('sub-name', value)
    }
  }
}
