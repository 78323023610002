import { render, staticRenderFns } from "@/components/templates/default/PageHeaderNuxt/menu/women/women.html?vue&type=template&id=0e6ecdb0&scoped=true&lang=html&"
import script from "./women.js?vue&type=script&lang=js&"
export * from "./women.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderNuxt/menu/women/women.scss?vue&type=style&index=0&id=0e6ecdb0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e6ecdb0",
  null
  
)

component.options.__file = "women.vue"
export default component.exports