import image from '../../images/images.js'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState({
      basket: (state) => ({...state.basket})
    }),
    image: () => image
  },
  methods: {
    showMenu () {
      this.$emit('show-menu-slider')
    }
  }
}
