import { render, staticRenderFns } from "@/components/templates/default/PageHeaderNuxt/menu/navigation/change-currency.html?vue&type=template&id=41291844&scoped=true&lang=html&"
import script from "./change-currency.js?vue&type=script&lang=js&"
export * from "./change-currency.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderNuxt/menu/navigation/change-currency.scss?vue&type=style&index=0&id=41291844&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41291844",
  null
  
)

component.options.__file = "change-currency.vue"
export default component.exports