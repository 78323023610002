export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    categories: [
      {
        id: 0,
        name: 'ESSENTIAL',
        link: '/glasses/prescription/essential/',
        values: [
          {
            id: 0,
            name: 'Savannah',
            link: '/glasses/prescription/savannah/'
          },
          {
            id: 1,
            name: 'Infinity',
            link: '/glasses/prescription/infinity/'
          },
          {
            id: 2,
            name: 'Aero',
            link: '/glasses/prescription/aero/'
          },
          {
            id: 3,
            name: 'Antares',
            link: '/glasses/prescription/antares/'
          },
          {
            id: 4,
            name: 'Cameo',
            link: '/glasses/prescription/cameo/'
          },
          {
            id: 5,
            name: 'Icon',
            link: '/glasses/prescription/icon/'
          },
          {
            id: 6,
            name: 'Lazer',
            link: '/glasses/prescription/lazer/'
          },
          {
            id: 7,
            name: 'Meridian',
            link: '/glasses/prescription/meridian/'
          },
          {
            id: 8,
            name: 'Matrix',
            link: '/glasses/prescription/matrix/'
          },
          {
            id: 9,
            name: 'Ocean Blue',
            link: '/glasses/prescription/ocean-blue/'
          },
          {
            id: 10,
            name: 'Owlet',
            link: '/glasses/prescription/owlet/'
          },
          {
            id: 11,
            name: 'Racing Green',
            link: '/glasses/prescription/racing-green/'
          }
        ]
      },
      {
        id: 1,
        name: 'DESIGNER',
        link: '/brands/',
        values: [
          {
            id: 0,
            name: 'Oakley',
            link: '/glasses/prescription/oakley/'
          },
          {
            id: 1,
            name: 'Ray-Ban',
            link: '/glasses/prescription/ray-ban/'
          },
          {
            id: 2,
            name: 'Tokyo Tom',
            link: '/glasses/prescription/tokyo-tom/'
          },
          {
            id: 3,
            name: 'Bolle',
            link: '/glasses/prescription/bolle/'
          },
          {
            id: 4,
            name: 'William Morris',
            link: '/glasses/prescription/william-morris/'
          },
          {
            id: 5,
            name: 'Reebok',
            link: '/glasses/prescription/reebok/'
          },
          {
            id: 6,
            name: 'Adidas',
            link: '/glasses/prescription/adidas/'
          },
          {
            id: 7,
            name: 'Kangol',
            link: '/glasses/prescription/kangol/'
          },
          {
            id: 8,
            name: 'Polaroid',
            link: '/glasses/prescription/polaroid/'
          },
          {
            id: 9,
            name: 'O`Neill',
            link: '/glasses/prescription/o-neill/'
          },
          {
            id: 10,
            name: 'Denis Tailor',
            link: '/glasses/prescription/denis-tailor/'
          },
          {
            id: 11,
            name: 'Diesel',
            link: '/glasses/prescription/diesel/'
          }
        ]
      },
      {
        id: 2,
        name: 'BOUTIQUE',
        link: '/glasses/prescription/boutique/',
        values: [
          {
            id: 0,
            name: 'Tiffany & Co',
            link: '/glasses/prescription/tiffany-co-/'
          },
          {
            id: 1,
            name: 'Chophard',
            link: '/glasses/prescription/chophard/'
          },
          {
            id: 2,
            name: 'Boss Hugo Boss',
            link: '/glasses/prescription/boss-hugo-boss/'
          },
          {
            id: 3,
            name: 'DVF',
            link: '/glasses/prescription/dvf/'
          },
          {
            id: 4,
            name: 'Persol',
            link: '/glasses/prescription/persol/'
          },
          {
            id: 5,
            name: 'Dolce & Gabbana',
            link: '/glasses/prescription/dolce-gabbana/'
          },
          {
            id: 6,
            name: 'Paul Costelloe',
            link: '/glasses/prescription/paul-costelloe/'
          },
          {
            id: 7,
            name: 'Mont Blanc',
            link: '/glasses/prescription/mont-blanc/'
          },
          {
            id: 8,
            name: 'Oliver Peoples',
            link: '/glasses/prescription/oliver-peoples/'
          },
          {
            id: 9,
            name: 'Moschino',
            link: '/glasses/prescription/moschino/'
          },
          {
            id: 10,
            name: 'Prada',
            link: '/glasses/prescription/prada/'
          },
          {
            id: 11,
            name: 'Ralph Lauren',
            link: '/glasses/prescription/ralph-lauren/'
          }
        ]
      }
    ]
  })
}
