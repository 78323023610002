import image from '../images/images.js'
import {mapState} from 'vuex'
import searchModal from './search-modal.vue'

export default {
  components: {
    searchModal
  },
  computed: {
    ...mapState({
      basket: (state) => ({...state.basket}),
      user: (state) => state.user.hashedPassword
    }),
    image: () => image,
    showAccountMenu: function () {
      const path = this.$route.path
      const routesForNuxtHeader = new RegExp('checkout|auth')
      return routesForNuxtHeader.test(path)
    }
  },
  methods: {
    toAccount () {
      if (this.user) {
        this.$router.push('/account/profile')
      } else {
        window.location.assign('https://ssfront-dev.ssyii.com/auth/login')
      }
    }
  }
}
