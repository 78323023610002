export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        id: 0,
        title: 'TryUsToday',
        text: 'great_value_as_seen_on_tv',
        image: 'https://d9qzjwuieyamt.cloudfront.net/5.3.37.3/res/images/banners/home/cat-wow-glasses_v2.jpg?20190902084628',
        link: '/glasses/prescription/'
      },
      {
        id: 1,
        title: '2for1Glasses',
        text: 'stylish_pairs_low_price_lense_coatings',
        image: 'https://d9qzjwuieyamt.cloudfront.net/5.3.37.4/res/images/banners/home/cat-two-for-one_v2.jpg?20190903121446',
        link: '/2for1-glasses/'
      },
      {
        id: 2,
        title: 'Sale',
        text: 'sale_on',
        image: 'https://d9qzjwuieyamt.cloudfront.net/5.3.37.4/res/images/banners/home/cat-ladies-glasses_v2.jpg?20190903121446',
        link: '/sale/'
      },
      {
        id: 3,
        title: 'CheapGlass',
        text: 'cheap_glasses_now_on',
        image: 'https://d9qzjwuieyamt.cloudfront.net/5.3.37.4/res/images/banners/home/cat-ladies-glasses_v2.jpg?20190903121446',
        link: '/cheap-glasses/'
      }
    ]
  })
}
