import {mapState} from 'vuex'
import {currentCountry} from '@/../config/helper'
import heplDropdown from './helpDropdown.vue'
import currency from '../menu/navigation/change-currency.vue'

export default {
  components: {
    heplDropdown,
    currency
  },
  data: () => ({
    sspayMode: false,
    domain: false
  }),
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      currencies: (state) => state.currency.allCurrency,
      selectedCurrency: (state) => state.currency.selected,
      calculatePrice: (state) => state.calculatePrice
    }),
    selectedCountryImage () {
      return this.countriesSite[this.$i18n.locale].image
    },
    loadedCurrencies () {
      return Object.values(this.currencies).length > 0
    },
    countriesSite () {
      return {
        en: {
          title: 'English',
          lang: 'en',
          image: this.confCdnUrl + '/res/images/_png/flags/uk.png',
          link: '/'
        },
        de: {
          title: 'Deutsch',
          lang: 'de',
          image: this.confCdnUrl + '/res/images/_png/flags/de.png',
          link: '/de'
        },
        cn: {
          title: 'Chinese',
          lang: 'cn',
          image: this.confCdnUrl + '/res/images/_png/flags/cn.png',
          link: '/cn'
        },
        ca: {
          title: 'Canadian',
          lang: 'ca',
          image: this.confCdnUrl + '/res/images/_png/flags/ca.png',
          link: '/ca'
        },
        es: {
          title: 'Espanol',
          lang: 'es',
          image: this.confCdnUrl + '/res/images/_png/flags/es.png',
          link: '/es'
        },
        au: {
          title: 'Australian',
          lang: 'au',
          image: this.confCdnUrl + '/res/images/_png/flags/au.png',
          link: '/au'
        },
        us: {
          title: 'American',
          lang: 'us',
          image: this.confCdnUrl + '/res/images/_png/flags/us.png',
          link: '/us'
        }
      }
    }
  },
  created: function () {
    this.$i18n.locale = this.$cookies.get('lang') ? this.$cookies.get('lang') : 'en'
    this.domain = currentCountry()
    this.sspayMode = !(this.$route.query.sspay === undefined)
  }
}
