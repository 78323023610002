export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    options: [
      {
        id: 0,
        name: 'dailies',
        image: 'fa fa-clock-o fa-5x',
        link: '/contact-lenses/daily-disposables/'

      },
      {
        id: 1,
        name: 'weeklies',
        image: 'fa fa-calendar-o fa-5x',
        link: '/contact-lenses/two-weekly-disposables/'

      },
      {
        id: 2,
        name: 'monthlies',
        image: 'fa fa-calendar-check-o fa-5x',
        link: '/contact-lenses/monthly-disposables/'

      }
    ],
    categories: [
      [
        {
          id: 0,
          name: 'ColouredLenses',
          link: '/contact-lenses/colored-contact-lenses/'
        },
        {
          id: 1,
          name: 'toric_astigmatism_lenses',
          link: '/contact-lenses/toric-astigmatic-lenses/'
        },
        {
          id: 2,
          name: 'MultifocalLenses',
          link: '/contact-lenses/multifocals/'
        },
        {
          id: 3,
          name: 'ExtendedWear',
          link: '/contact-lenses/extended-wear-lenses/'
        }
      ],
      [
        {
          id: 0,
          name: 'bausch_lomb',
          link: '/contact-lenses/extended-wear-lenses/'
        },
        {
          id: 1,
          name: 'ciba_vision',
          link: '/contact-lenses/extended-wear-lenses/'
        },
        {
          id: 2,
          name: 'coopervision',
          link: '/contact-lenses/extended-wear-lenses/'
        },
        {
          id: 3,
          name: 'johnson_lohnson',
          link: '/contact-lenses/extended-wear-lenses/'
        }
      ]
    ]
  })
}
