import { render, staticRenderFns } from "@/components/templates/default/PageHeaderNuxt/menu/helpme-choose/helpme-choose.html?vue&type=template&id=62cc3eed&scoped=true&lang=html&"
import script from "./helpme-choose.js?vue&type=script&lang=js&"
export * from "./helpme-choose.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderNuxt/menu/helpme-choose/helpme-choose.scss?vue&type=style&index=0&id=62cc3eed&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62cc3eed",
  null
  
)

component.options.__file = "helpme-choose.vue"
export default component.exports