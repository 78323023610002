export default {
  data: () => ({
    options: [
      {
        id: 0,
        name: 'HelpAndInfo',
        icon: 'fa fa-question-circle',
        link: '/information/'
      },
      {
        id: 1,
        name: 'DelvInfo',
        icon: 'fa fa-plane',
        link: '/info/shipping-delivery/'
      },
      {
        id: 2,
        name: 'FootReturns',
        icon: 'fa fa-reply',
        link: '/info/returns/'
      },
      {
        id: 3,
        name: 'YourPD',
        icon: 'fa fa-eye',
        link: '/info/pupillary-distance/'
      },
      {
        id: 4,
        name: 'YourPrescription',
        icon: 'fa fa-newspaper-o',
        link: '/info/help-with-prescription/'
      },
      {
        id: 5,
        name: 'About',
        icon: 'fa fa-users',
        link: '/about-us/'
      },
      {
        id: 6,
        name: 'Contact',
        icon: 'fa fa-envelope-open-o',
        link: '/information/'
      },
      {
        id: 7,
        name: 'TrackOrder',
        icon: 'fa fa-check-square-o',
        link: '/account/orders/'
      }
    ]
  })
}
