import { render, staticRenderFns } from "@/components/templates/default/PageHeaderNuxt/contacts/contacts.html?vue&type=template&id=354eeb21&scoped=true&lang=html&"
import script from "./contacts.js?vue&type=script&lang=js&"
export * from "./contacts.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderNuxt/contacts/contacts.scss?vue&type=style&index=0&id=354eeb21&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354eeb21",
  null
  
)

component.options.__file = "contacts.vue"
export default component.exports