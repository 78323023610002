import { render, staticRenderFns } from "@/components/templates/default/PageHeaderNuxt/menu/men-women-dropdown/men-women-dropdown.html?vue&type=template&id=c4e07bba&scoped=true&lang=html&"
import script from "./men-women-dropdown.js?vue&type=script&lang=js&"
export * from "./men-women-dropdown.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderNuxt/menu/men-women-dropdown/men-women-dropdown.scss?vue&type=style&index=0&id=c4e07bba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4e07bba",
  null
  
)

component.options.__file = "men-women-dropdown.vue"
export default component.exports