export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isActive: false
  }),
  methods: {
    showSubmenu (value) {
      this.isActive = true
      this.$emit('name-submenu', value)
    }
  }
}
