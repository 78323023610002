import {mapState} from 'vuex'
import {currentCountry} from '@/../config/helper'

export default {
  data () {
    return {
      sspayMode: false,
      domain: false
    }
  },
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      currencies: (state) => state.currency.allCurrency,
      selectedCurrency: (state) => state.currency.selected,
      calculatePrice: (state) => state.calculatePrice
    }),
    selectedCountryImage () {
      return this.countriesSite[this.domain || this.lang] ? this.countriesSite[this.domain || this.lang].image : ''
    },
    selectedCountry () {
      return this.countriesSite[this.domain || this.lang] ? this.countriesSite[this.domain || this.lang].lang : ''
    },
    loadedCurrencies () {
      return Object.values(this.currencies).length > 0
    },
    countriesSite () {
      return {
        en: {
          title: 'English',
          lang: 'en',
          image: this.confCdnUrl + '/res/images/_png/flags/uk.png',
          link: '/'
        },
        de: {
          title: 'Deutsch',
          lang: 'de',
          image: this.confCdnUrl + '/res/images/_png/flags/de.png',
          link: '/de'
        },
        cn: {
          title: 'Chinese',
          lang: 'cn',
          image: this.confCdnUrl + '/res/images/_png/flags/cn.png',
          link: '/cn'
        },
        ca: {
          title: 'Canadian',
          lang: 'ca',
          image: this.confCdnUrl + '/res/images/_png/flags/ca.png',
          link: '/ca'
        },
        es: {
          title: 'Espanol',
          lang: 'es',
          image: this.confCdnUrl + '/res/images/_png/flags/es.png',
          link: '/es'
        },
        au: {
          title: 'Australian',
          lang: 'au',
          image: this.confCdnUrl + '/res/images/_png/flags/au.png',
          link: '/au'
        },
        us: {
          title: 'American',
          lang: 'us',
          image: this.confCdnUrl + '/res/images/_png/flags/us.png',
          link: '/us'
        }
      }
    }
  },
  methods: {
    setCurrency (countryNumber) {
      this.$store.dispatch('setCurrency', countryNumber)
    },
    changeLanguage (location) {
      this.$i18n.locale = location
      localStorage.setItem('lang', location)
      this.$store.dispatch('changeLanguage', location)
      this.$cookies.set('lang', location)
    }
  },
  created: function () {
    this.domain = currentCountry()
    this.sspayMode = !(this.$route.query.sspay === undefined)
  }
}
