import {mapState} from 'vuex'
import men from '../men/men.vue'
import women from '../women/women.vue'
import offers from '../offers/offers.vue'
import contactLenses from '../contact-lenses/contact-lenses.vue'
import brands from '../brands/brands.vue'
import more from '../more/more.vue'
import helpmeChoose from '../helpme-choose/helpme-choose.vue'
import image from '../../images/images.js'
import currency from './change-currency.vue'

export default {
  name: 'Navigation',
  components: {
    men,
    women,
    offers,
    contactLenses,
    brands,
    more,
    helpmeChoose,
    currency
  },
  data: () => ({
    nameSubMenu: '',
    isOpen: false,
    nameOptions: '',
    changeCurrency: false
  }),
  computed: {
    ...mapState({
      apiHost: (state) => state.apiHost,
      currencies: (state) => state.currency.allCurrency,
      selectedCurrency: (state) => state.currency.selected,
      calculatePrice: (state) => state.calculatePrice
    }),
    selectedCountryImage () {
      return this.countriesSite[this.$i18n.locale].image
    },
    loadedCurrencies () {
      return Object.values(this.currencies).length > 0
    },
    countriesSite () {
      return {
        en: {
          title: 'English',
          lang: 'en',
          image: this.confCdnUrl + '/res/images/_png/flags/uk.png',
          link: '/'
        },
        de: {
          title: 'Deutsch',
          lang: 'de',
          image: this.confCdnUrl + '/res/images/_png/flags/de.png',
          link: '/de'
        },
        cn: {
          title: 'Chinese',
          lang: 'cn',
          image: this.confCdnUrl + '/res/images/_png/flags/cn.png',
          link: '/cn'
        },
        ca: {
          title: 'Canadian',
          lang: 'ca',
          image: this.confCdnUrl + '/res/images/_png/flags/ca.png',
          link: '/ca'
        },
        es: {
          title: 'Espanol',
          lang: 'es',
          image: this.confCdnUrl + '/res/images/_png/flags/es.png',
          link: '/es'
        },
        au: {
          title: 'Australian',
          lang: 'au',
          image: this.confCdnUrl + '/res/images/_png/flags/au.png',
          link: '/au'
        },
        us: {
          title: 'American',
          lang: 'us',
          image: this.confCdnUrl + '/res/images/_png/flags/us.png',
          link: '/us'
        }
      }
    },
    image: () => image
  },
  methods: {
    showOptions (name) {
      this.isOpen = true
      this.nameOptions = name
    },
    hideOptions () {
      this.isOpen = false
      this.nameSubMenu = ''
    },
    hideMenu () {
      this.$emit('hide-menu-slider')
    },
    showCurrency () {
      this.changeCurrency = !this.changeCurrency
    },
    nameSubmenu (value) {
      this.nameSubMenu = value.name
    }
  }
}
