import { render, staticRenderFns } from "@/components/templates/default/PageHeaderNuxt/menu/more/more.html?vue&type=template&id=72d9c565&scoped=true&lang=html&"
import script from "./more.js?vue&type=script&lang=js&"
export * from "./more.js?vue&type=script&lang=js&"
import style0 from "@/components/templates/default/PageHeaderNuxt/menu/more/more.scss?vue&type=style&index=0&id=72d9c565&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d9c565",
  null
  
)

component.options.__file = "more.vue"
export default component.exports